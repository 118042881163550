// Entry point for the build script in your package.json
//= require hammerjs

//= require draggable

import "trix"
import "@rails/actiontext"
import { fabric } from 'fabric';


// app/assets/javascripts/application.js

document.addEventListener('DOMContentLoaded', function () {
    makeEditable('editable-nume');
    makeEditable('editable-numele_invitatiei');
    makeEditable('editable-introducere');
    makeEditable('editable-locatie');
    makeEditable('editable-text-confirmare');
    makeEditable('editable-intro');
    makeEditable('editable-restaurant');
  });
  
  function makeEditable(elementId) {
    const editableElement = document.getElementById(elementId);
  
    if (editableElement) {
      editableElement.contentEditable = true;
      editableElement.addEventListener('click', function () {
        showToolBar(elementId);
      });
    }
  }
  
  function showToolBar(elementId) {
    const toolBar = document.getElementById('tool-bar');
    const editableElement = document.getElementById(elementId);
  
    if (toolBar && editableElement) {
      toolBar.innerHTML = ''; // Resetarea bara de unelte
  
      // Adaugarea butoanelor in bara de unelte
      const editButton = createButton('Editează Textul', () => editElement(editableElement));
      const fontButton = createButton('Schimbă Fontul', () => changeFont(editableElement));
      const colorButton = createButton('Schimbă Culoarea', () => changeColor(editableElement));
      const sizeButton = createButton('Schimbă Mărimea', () => changeSize(editableElement));
      const rearrangeButton = createButton('Rearanjează', () => rearrangeElement(editableElement));
      const duplicateButton = createButton('Duplică', () => duplicateElement(editableElement));
      const deleteButton = createButton('Șterge', () => deleteElement(editableElement));
  
      // Adăugarea butoanelor în bara de unelte
      toolBar.appendChild(editButton);
      toolBar.appendChild(fontButton);
      toolBar.appendChild(colorButton);
      toolBar.appendChild(sizeButton);
      toolBar.appendChild(rearrangeButton);
      toolBar.appendChild(duplicateButton);
      toolBar.appendChild(deleteButton);
    }
  }
  
  function createButton(text, onClick) {
    const button = document.createElement('button');
    button.textContent = text;
    button.addEventListener('click', onClick);
    return button;
  }
  
  function editElement(element) {
    // Implementează lógica pentru a edita elementul (salvarea modificărilor în baza de date, etc.)
  }
  
  function changeFont(element) {
    // Implementează lógica pentru a schimba fontul (salvarea modificărilor în baza de date, etc.)
  }
  
  function changeColor(element) {
    // Implementează lógica pentru a schimba culoarea (salvarea modificărilor în baza de date, etc.)
  }
  
  function changeSize(element) {
    // Implementează lógica pentru a schimba mărimea (salvarea modificărilor în baza de date, etc.)
  }
  
  function rearrangeElement(element) {
    // Implementează lógica pentru a rearanja elementul (salvarea modificărilor în baza de date, etc.)
  }
  
  function duplicateElement(element) {
    // Implementează lógica pentru a duplica elementul (salvarea modificărilor în baza de date, etc.)
  }
  
  function deleteElement(element) {
    // Implementează lógica pentru a șterge elementul (salvarea modificărilor în baza de date, etc.)
  }
  










  



  